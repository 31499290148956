/* Postcard section */

.water-bg {
    background-color: #0e121b;
    background-image: url('../images/water-bg.png');
    background-position: top;
    background-repeat: no-repeat;
    height: 700px;
}

.postcard {
    display: block;
    margin: 0 auto;
    width: 1450px;
    max-width: 100%;
    top: 140px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 1;
}

.postcard-bg {
    background-image: url('../images/postcard.png');
    background-position: top;
    background-repeat: no-repeat;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 1370px 660px;
    height: 980px;
    top: 120px;
    position: relative;
    z-index: 1;
}

.postcard-mobile-bg {
    background-image: url('../images/postcard-mobile.png');
    background-position: top;
    background-repeat: no-repeat;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 980px;
    top: 120px;
    position: relative;
    z-index: 1;
}

.postcard-as-container {
    position: relative;
    display: block;
    height: 520px;
    margin: 0 auto;
    width: 820px !important;
    max-width: 100%;
    padding-right: 20px;
    z-index: 1;
    align-items: flex-end;
}

.postcard-buttons {
    position: relative;
    bottom: 70px;
    margin: 0 auto !important;
    z-index: 10;
    width: 780px !important;
    align-items: flex-end !important;
}

.postcard-btn {
    display: block;
    text-align: center;
    font-family: 'Kabel';
    font-size: 14px;
    color: #f3f3de;
    background-color: transparent;
    border: 3px solid #f3f3de;
    border-radius: 25px;
    height: 30px;
    width: 170px;
    line-height: 1;
}

.mint-postcard-btn {
    position: relative;
    display: block;
    text-align: center;
    font-family: 'Kabel';
    font-size: 14px;
    color: #f3f3de;
    background-color: transparent;
    border: 3px solid #f3f3de;
    padding: 3px 0px 2px 0px;
    border-radius: 25px;
    height: 30px;
    width: 170px;
    line-height: 1.3;
}

.postcard-btn:hover {
    cursor: pointer;
    background-color: #25a183;
    border: 3px solid #25a183;
    transition: background-color .1s, border .1s;
}

.mint-postcard-btn:hover {
    cursor: pointer;
    background-color: #25a183;
    border: 3px solid #25a183;
    transition: background-color .1s, border .1s;
}

.under-postcard-button-container {
    background-color: #0e121b;
    width: 100%;
    padding: 50px 0px 20px 0px;
}

.postcard-buttons-divider-line {
    background-color: #30384d;
    width: 80%;
    height: 2px;
    display: block;
    margin: 50px auto 0px auto;
}

/* Parrot Pass Section */


.parrot-pass-content {
    position: relative;
    background-color: #0e121b;
    color: white;
    overflow: hidden;
    align-content: center;
}

.parrot-pass-content-container {
    justify-content: center;
    padding: 50px 0px 125px 0px;
    min-height: 90vh;
}

.parrot-pass-image {
    display: block;
    margin: 0 auto;
    width: 90%;
}

.parrot-pass-text-container {
    text-align: right;
}

.parrot-pass-text-content {
    justify-content: flex-end;
    z-index: 1;
    padding-right: 50px;
}

.parrot-pass-text-content p {
    font-size: medium;
}

.mint-button {
    align-self: flex-end;
    display: block;
    font-family: 'Kabel';
    color: white;
    background: rgb(231,104,30);
    background: linear-gradient(130deg, rgba(231,104,30,1) 0%, rgba(241,125,57,1) 35%, rgba(252,150,89,1) 100%);
    padding: 5px 0px;
    border-radius: 25px;
    border-width: 0px;
    width: 150px;
    margin-top: 20px;
    text-align: center;
}

.mint-button:hover {
    cursor: pointer;
    background: rrgb(252, 136, 69)35%;
    background: linear-gradient(130deg, rgb(240, 114, 42) 0%, rgb(252, 136, 69), rgb(255, 165, 112) 100%);
    transition: background .1s;
}

.parrot-pass-header-text {
    color: #f3602d;
}

.palm-trees {
    position: absolute;
    left: 75%;
    bottom: -30px;
}

/* Road Map */

.section-header {
    position: relative;
    display: block;
    margin: 0 auto;
    background-color: #2a2e46;
    border-radius: 50px;
    color: #f3602d;
    width: 300px;
    padding: 10px;
    text-align: center;
    bottom: 30px;
}

.road-map-content-container {
    justify-content: center;
    padding: 50px 0px;
}

.road-map-content {
    position: relative;
    background-color: #2a2e46;
    color: white;
    padding-bottom: 100px;
}

.road-map-text-item {
    margin-bottom: 20px;
}

.road-map-text-item-header-var1 {
    color: #25a183;
}

.road-map-text-item-header-var2 {
    color: #f6a346;
}

.road-map-text-item-header-var3 {
    color: #f65c46;
}

.road-map-text-item-header-incomplete {
    color: #777;
}

.roadmap-completion-text {
    color: #f3602d;
}

.roadmap-image {
    width: 250px;
    display: block;
    margin: 0 auto;
    z-index: 1;
}

.sedan {
    position: absolute;
    left: 3%;
    bottom: -90px;
}

/* Team Container */

.team-text-container {
    color: white;
    font-size: medium;
    text-align: right;
    padding-right: 50px;
    padding-left: 20px;
}

.team-name-text {
    margin-top: 30px;
}

.team-content {
    background-color: #30a094;
}

.team-content-container {
    padding: 150px 0px;
}

.team-pfp {
    border-radius: 15px;
    width: 180px;
    margin: 10px;
}

.team-header-text {
    color: #c5395f;
}

.name-highlight {
    color: #c5395f;
    font-weight: bold;
}

.contract-button-container {
    margin: 25px 0px;
}

/* Footer Section */

.footer-logo {
    height: 100px;
}

.footer-container {
    position: relative;
    height: 300px;
    background-color: #0e121b;
    border-bottom: 10px solid #de31e0;
}

.footer-content {
    height: 300px;
    width: 100%;
}

.contract-button {
    display: block;
    border-width: 0px;
    border-radius: 15px;
    background-color: #2c3449;
    color: white;
    padding: 5px 10px;
    margin: 10px auto;
}

.contract-button:hover {
    background-color: #464f68;
    cursor: pointer;
}


@media only screen and (max-width: 1280px) {
    .team-text-container {
        text-align: center;
        padding-right: 0px;
        margin-top: 20px;
    }

    .parrot-pass-text-container {
        text-align: center;
    }

    .parrot-pass-text-content {
        margin-top: 30px !important;
        justify-content: center;
        padding-right: 0px;
    }

    .mint-button {
        align-self: center;
    }
}


@media only screen and (max-width: 960px) {

    .postcard {
        top: 70px;
    }

    .postcard-as-container {
        padding-right: 0px;
    }

    .postcard-buttons {
        bottom: 0px;
    }

    .parrot-pass-text-content {
        margin-top: 30px !important;
        padding-right: 0px;
    }

    .roadmap-header-item {
        font-size: 24px !important;
    }
}

@media only screen and (max-width: 480px) {

    .sedan {
        position: absolute;
        left: -40%;
        bottom: -90px;
    }

    .team-pfp {
        border-radius: 15px;
        height: auto;
        width: 40%;
        margin: 10px;
    }

    .roadmap-completion-text {
        position: relative;
        bottom: 30px;
    }

}
