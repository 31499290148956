.icon-container {
    position: relative;
    bottom: 1px;
    display: flex;
    align-items: center;
}

.icon {
    position: relative;
}

.icon:hover {
    cursor: pointer;
}

.onboarding-nav-wrapper {
    position: relative;
}

.container {
    align-items: flex-start;
    background-color: #0e121b;
    background-position: center;
    justify-self: center;
    display: flex;
    height: 50px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 100;
    margin-bottom: 10px;
}

.menu {
    position: relative;
    margin-top: 8px;
    right: 5px;
}

.navbar-bg {
    position: absolute;
    display: block;
    width: 100%;
}
