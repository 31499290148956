html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* unvisited link */
a:link {
  text-decoration: none;
}

/* visited link */
a:visited {
  text-decoration: none;
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
  text-decoration: none;
}

/* unvisited link */
button a:link {
  color: inherit;
  text-decoration: none;
}

/* visited link */
button a:visited {
  color: inherit;
  text-decoration: none;
}

/* mouse over link */
button a:hover {
  color: inherit;
  text-decoration: none;
}

/* selected link */
button a:active {
  color: inherit;
  text-decoration: none;
}

.white-text {
  color: white;
}