.mint-container {
    height: 100%;
    min-height: 100vh;
    width: 100%;
}

.mint-screen-background {
    background-image: url('../images/mint-page-background.png');
    background-position: center;
    background-color: #181f2e;
}

.mint-paused-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    text-align: center;
}

.mint-form-container {
    align-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    border: 3px solid #c5395f;
    width: 350px;
    max-width: 100%;
    background-color: #1f2637;
    justify-content: space-between;
    padding: 10px 20px 20px 20px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin: 50px auto;
}

.mint-form-container-loader {
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    height: 550px;
    width: 350px;
    max-width: 100%;
    background-color: #1f2637;
    justify-content: center;
    padding: 10px 20px 20px 20px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 50px;
    margin-bottom: 50px;
}

.mint-your-pass {
    justify-self: flex-start;
    width: 100%;
}

.mint-form-view {
    background-color: #333c52;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    height: 100%;
    padding: 10px;
}

.text-shadow {
    text-shadow: 5px 5px 1px #222 !important;
}

.free-mint-form-view {
    justify-content: center;
}

.free-mint-form-view-text-container {
    background-color: #2c3449;
    border: 2px solid #c5395f;
    border-radius: 15px;
    padding: 20px;
    margin: 10px 0px 20px 0px;
}

.mint-button {
    align-self: center;
    color: white !important;
    margin: 20px 0px !important;
}

.mint-arrow-button-container {
    position: relative;
    width: 75px !important;
    margin: 25px auto 0px auto;
    bottom: 80px;
}

.mint-arrow-button {
    background-color: #181f2e !important;
}
.mint-arrow-button:hover {
    background-color: #181f2e !important;
}

.switch-network-button {
    align-self: center;
    color: white !important;
    margin-top: 40px !important;
    width: 200px !important;
    display: block;
    font-family: 'Kabel';
    background: rgb(231,104,30);
    background: linear-gradient(130deg, rgba(231,104,30,1) 0%, rgba(241,125,57,1) 35%, rgba(252,150,89,1) 100%);
    padding: 5px 0px;
    border-radius: 25px;
    border-width: 0px;
    width: 150px;
    margin-top: 20px;
    text-align: center;
}

.mint-text {
    color: white !important;
}

.mint-select-input {
    color: white !important;
}

.loader {
    align-self: center;
    justify-self: center;
}

.amount-minted-container {
    background-color: #1f2637;
    border-radius: 15px;
    padding: 4px;
    text-align: center;
}

.amount-minted-container-border {
    border-radius: 13px;
    border: 2px solid #c5395f;
    padding: 10px;
}

.mint-linear-progress {
    margin: 10px 0px;
}

.stats-container {
    margin-top: 20px;
}

.stats-content {
    padding: 0px 10px;
}

.arrow {
    height: 50px;
    width: 50px;
}
.arrow:hover {
    cursor: pointer;
}

.mint-tabs {
    margin-bottom: 30px;
}

@media only screen and (max-width: 960px) {

    .mint-form-container {
        margin-top: 75px;
    }

}